.Label {
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.87);
    margin: 5px;
}

.Row {
    height: 90px;
    display: flex;
    flex-direction: column;
}


.MobileRow {
    display: flex;
    height: 40px;
    width: 90%;
    flex-direction: column;
}

.Input {
    background: #FFFFFF;
    border: 1px solid #E5E5E5;
    box-sizing: border-box;
    border-radius: 2px;
    height: 38px;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.4);
    padding: 0 0 0 16px;
    width: 100%;
    flex-shrink: 0;
    outline: none;
}