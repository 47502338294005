.ContentList {
  display: flex;
  flex-direction: column;
  margin: 24px 16px 0px 16px;
}

.videoResDiv {
  display: flex;
  flex-direction: row;
  /* align-items: center;
  justify-content: space-between; */
}



.ContentListHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  opacity: 0.87;
  position: relative;
  /* padding: 12px 0; */
}

.InsideFolderHeader {
  display: flex;
  /* padding: 12px 0; */
  padding-bottom: 16px;
  background: #ffffff;
  cursor: pointer;
  position: relative;
  align-items: center;
  font-weight: 450;
  font-size: 14px;
  line-height: 150%;
  /* identical to box height, or 21px */

  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.87);
}

.cheatBorder {
  height: 1px;
  background: #e5e5e5;
  width: 109%;
  position: absolute;
  bottom: 0;
  left: -5%;
}

.ContentListings {
  display: flex;
  flex-direction: column;
  /* margin-bottom: 16px; */
}

.ContentListItem {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 16px 0px;
  cursor: pointer;
}

.ContentListItemImg {
  display: flex;
  justify-content: center;
}

.ContentListItemContent {
  margin-left: 12px;
  display: flex;
  flex-direction: column;
}

.ContentListItemContentRow1 {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.87;
  display: flex;
  align-items: center;
}

.ContentListItemContentRow1PreviewTag {
  display: flex;
  align-items: center;
  background: #ff9921;
  border: 1px solid #ff9921;
  border-radius: 2px;
  padding: 2px 4px;
  margin-left: 8px;
  cursor: pointer;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  color: #ffffff;
  outline: none;
}

/* .ContentListItemContentRow1PreviewTag label {
   
} */

.ContentListItemContentRow2 {
  display: flex;
  align-items: center;
  margin-top: 4px;
}

.ContentListItemContentRow2Text {
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 16px;
  margin-left: 8px;

  display: flex;
  align-items: center;

  color: #000000;

  mix-blend-mode: normal;
  opacity: 0.6;
}

.ContentListFooter {
  padding-top: 16px;
  padding-bottom: 24px;
}

.ContentListBtn {
  background: #ffffff;
  border: 1px solid #009ae0;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 0px;
  color: #009ae0;
  cursor: pointer;
  outline: none;
  width: 100%;
}

.videoCrossImg {
  position: absolute;
  /* top: -7%; */
  right: 0%;
  bottom: 105%;
  cursor: pointer;
}

@media(max-width:1000px) {
  .ContentList {
    /* margin-bottom: 80px; */
  }
}

@media (min-width: 700px) {
  .ContentList {
    /* margin: 24px; */
    margin-bottom: 0px;
  }

  .ContentListFooter {
    /* border-bottom: 1px solid rgba(229, 229, 229, 0.6); */
    display: flex;
    justify-content: center;
    padding-top: 16px;
    /* border-top: 1px dashed #cccccc; */
    /* width: 40%; */
    /* margin: auto; */
  }

  .ContentListBtn {
    width: 40%;
    /* margin: auto; */
  }

  .videoCrossImg {
    position: absolute;
    /* top: -5%;
    right: -4%; */
    right: 0%;
    bottom: 104%;
    cursor: pointer;
  }

  .ContentListHeading {
    font-size: 18px;
  }

  .ContentListings {
    margin-bottom: 0px;
  }
}

@media (max-width: 1000px) {
  .videoResDiv {
    flex-direction: column;
  }
}