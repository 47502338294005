.MoreCourses {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.MoreCoursesHeader {
  background: #ffffff;
  position: fixed;
  top: 0px;
  height: 64px;
  box-sizing: border-box;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e5e5e5;
  width: 100%;
}

.MoreCoursesBackBtn {
  /* position: absolute;
    left: 16px;
    top: 18px; */
  background-color: white;
  outline: none;
  border: none;
  cursor: pointer;
  margin-right: 8px;
}

.MoreCoursesHeading {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
}

.MoreCoursesContentList {
  /* display: flex;
    flex-direction: column; */
  display: grid;
  grid-template-columns: 1fr;
  align-content: baseline;
  margin: 24px 0px 24px 0px;
  padding: 8px 0px;
  width: 100%;
  cursor: pointer;
}

.MoreCoursesContent {
  display: flex;
  /* align-items: center; */
  padding: 16px 0px;
  margin: 0px 16px;
  border-bottom: 1px solid #e5e5e5;
}

.MoreCoursesContentImg {
  /* width: 150px; */
  /* height: 100px; */
  position: relative;
  width: 110px;
  height: 80px;
  border: none;
  flex-shrink: 0;
}

.MoreCoursesContentImage {
  width: 100%;
  height: 100%;
}

.imageTag {
  padding: 0px 8px;
  position: absolute;
  top: 10px;
  left: 0px;
  background: #ff4058;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
  border-radius: 0px 4px 4px 0px;
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: #ffffff;
}

.MoreCoursesContentText {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.MoreCoursesContentRow1 {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  opacity: 0.87;
  /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */

  /* overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical; */
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.MoreCoursesContentRow2 {
  display: flex;
  margin-top: 8px;
}

.MoreCoursesContentRupees {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;

  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.87);
}

.MoreCoursesContentDiscount {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-left: 16px;

  display: flex;
  align-items: center;
  text-decoration-line: line-through;

  color: rgba(0, 0, 0, 0.45);
}

.MoreCoursesContentOffer {
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  display: flex;
  align-items: center;
  margin-left: 16px;
  color: #ff5656;
}

@media (max-width: 1000px) {
  .MoreCoursesContentList {
    margin-top: 0;
    margin-bottom: 100px;
  }
}

@media (min-width: 700px) {
  .MoreCourses {
    width: 80%;
    margin: auto;
  }

  .MoreCoursesHeader {
    justify-content: flex-start;
    border-bottom: none;
    font-size: 18px;
    line-height: 150%;
    /* margin-top: 8px; */
    /* padding-top: 17px; */
    top: 0px;
  }

  .MoreCoursesBackBtn {
    left: 0px;
  }

  .MoreCoursesHeading {
    margin-left: 48px;
  }

  .MoreCoursesContentList {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 32px;
    padding: 16px 0px;
  }

  .MoreCoursesContent {
    display: flex;
    flex-direction: column;
    /* align-items: center; */
    padding: 0px;
    margin: 0px;
    background: #ffffff;
    border-bottom: none;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
  }

  .MoreCoursesContentImg {
    width: 100%;
    height: 195px;
    border-radius: 4px 4px 0px 0px;
    /* width: 316px;
      height: 200px; */
    border: none;
  }

  .MoreCoursesContentImage {
    width: 100%;
    height: 100%;
  }

  .MoreCoursesContentText {
    margin: 16px;
  }

  .imageTag {
    padding: 4px 12px;
    top: 16px;

    font-weight: 500;
    font-size: 14px;
  }

  .MoreCoursesContentRow1 {
    line-height: 24px;
    width: unset;
    white-space: unset;
    overflow: unset;
    text-overflow: unset;
  }

  .MoreCoursesContentRow2 {
    margin-top: 16px;
  }
}
