.desktopContainer {
  background: #ffffff;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  /* margin: 16px 3.5% 24px 16%; */
  margin: 16px 3.5% 24px 150px;
  /* position: sticky; */
  /* top: 80px; */
}

.mobileContainer {
  display: none;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  flex-direction: column;
}

.header {
  border-top: 1px solid #e5e5e5;
  border-bottom: 1px solid #e5e5e5;
  display: flex;
  /* padding: 24px; */
  position: sticky;
  top: 88px;
  z-index: 1;
  background: #ffffff;
  border-radius: 4px;
}

.selected {
  background: none;
  border-top: none;
  border-left: none;
  border-right: none;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  border-bottom: 4px solid #009ae0;
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer;
  outline: none;
  padding: 17px 24px 9px 24px;
}

.unselected {
  background: none;
  border: none;
  cursor: pointer;
  outline: none;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  padding: 17px 24px 13px 24px;
  color: rgba(0, 0, 0, 0.6);
}

.cheatBar {
  height: 24px;
  width: 102%;
  position: absolute;
  left: -1%;
  /* display: none; */
  background: white;
  top: -25px;
}

@media (max-width: 1000px) {
  .desktopContainer {
    display: none;
  }

  .mobileContainer {
    margin: 0px;
    width: 100%;
    display: flex;
    border: none;
  }
}