.App {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: #ffffff;
}

.AppHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 0px 1px 3px rgba(172, 173, 175, 0.2),
    0px 2px 2px rgba(172, 173, 175, 0.12), 0px 0px 2px rgba(172, 173, 175, 0.14);
  /* position: sticky;
  top:0; */
  /* position: fixed; */
  height: 64px;
  width: 100%;
  z-index: 10;
}

.course-name {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* margin-left: 16%; */
  margin-left: 150px;
}

.shareButton {
  /* margin-right: 150px; */
  height: 32px;
  box-sizing: border-box;
  border: 1px solid #009ae0;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  color: #009ae0;
  cursor: pointer;
  background: none;
  outline: none;
}

.Toastify__toast-container {
  z-index: 99999999999999 !important;
}

.AppContainer {
  background: #ffffff;
  /* height: 100vh; */
  /* height:calc(100vh - 64px); */
  width: 100%;
  display: flex;
  flex-direction: row;
  margin: 64px 0;
  /* overflow: auto; */
}

.AppHeaderText {
  background: #ffffff;
  border: none;
  outline: none;
  user-select: none;
  cursor: pointer;
  padding: 0 40px 0 32px;
  font-size: 25px;
  line-height: 21px;
  display: flex;
  align-items: center;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.6);
}

.AppContainer-div-upper {
  width: 100%;
  background: linear-gradient(
      89.92deg,
      #f3faff 0.07%,
      rgba(238, 247, 254, 0) 99.93%
    ),
    #fcfcfc;
}

.AppContainer-div {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.AppContainer-div-right {
  display: flex;
  flex-direction: column;
  /* width: 100%; */
  width: 26%;
}

.AppHeader-left {
  display: flex;
  align-items: center;
  margin-left: 150px;
}

.AppHeader-right {
  background: #ffffff;
  border: 1px solid #009ae0;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: right;
  background: none;
  outline: none;
  padding: 10px 36px;
  color: #009ae0;
  margin-right: 150px;
  cursor: pointer;
}

.mobileFooter {
  display: none;
}

.shieldDiv {
}

.footerText {
}

.mobileBtn {
}

.slick-dots li {
  margin-right: 0 !important;
}

/* .slick-dots li.slick-active button:before {
  background: #F2C94C;
} */
/* .slick-dots li button:before {
  background: #F2C94C;

} */

.slick-dots li {
  background: #f1f1f1;
  border-radius: 2px;
  width: 25px;
  height: 5px;
  margin-right: 5px !important;
}

.slick-dots li button {
  height: 5px;
  width: 4px;
  top: 0px;
  background: #f1f1f1;
  border-radius: 2px;
  padding: 0px;
}

.slick-dots li button:before {
  background: #f1f1f1;
  width: 24px;
  height: 5px;
  color: inherit;
}

.slick-dots li.slick-active button:before {
  color: inherit;
  width: 24px;
  height: 5px;
  color: inherit;
}

.slick-dots li.slick-active button:before {
  color: inherit;
  width: 24px;
  height: 4px;
  background: #f2c94c;
  border-radius: 2px;
}

@media (max-width: 1000px) {
  .AppHeader {
    padding: 12px 16px;
    box-sizing: border-box;
  }

  .AppContainer {
    flex-direction: column;
    /* padding:16px 0 0 0; */
  }

  .AppContainer-div {
    flex-direction: column-reverse;
  }

  .AppContainer-div-right {
    width: 100%;
  }

  .AppHeaderText {
  }

  .AppHeader-left {
    margin-left: 0px;
  }

  .AppHeader-right {
    border: 1px solid #009ae0;
    box-sizing: border-box;
    border-radius: 4px;
    margin-right: 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding: 5px 11px;
    display: flex;
    justify-content: center;
    outline: none;
  }

  .AppHeaderText {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #2d2d45;
    margin: 0;
    padding: 0 12px;
  }

  .mobileFooter {
    position: fixed;
    bottom: 0px;
    display: flex;
    background: #ffffff;
    box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.24);
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding-bottom: 16px;
    width: 100%;
    z-index: 5;
  }

  .shieldDiv {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    padding-top: 8px;
    color: rgba(0, 0, 0, 0.6);
  }

  .footerText {
    margin: 0;
  }

  .mobileBtn {
    background: #009ae0;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    width: 85%;
    border: none;
    outline: none;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    text-align: center;
    padding: 14px 0;
    color: #ffffff;
    margin-top: 16px;
  }

  .shareButton {
    display: none;
  }
}

.addContent {
  margin-top: 3rem;
  background-color: #fff;
  padding: 2rem;
  padding-left: 2rem !important;
  padding-right: 2rem !important;
  text-align: left;
  margin-left: 2rem;
  -webkit-box-shadow: 0px 2px 8px rgba(151, 151, 151, 0.21708);
  box-shadow: 0px 2px 8px rgba(151, 151, 151, 0.21708);
}

.addContent__heading {
  padding-bottom: 1.5rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 24px;
  font-weight: 500;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.addContent__form {
  margin: 0.75rem 0rem !important;
}

.addContent__content {
  text-align: center;
}

.addContent__content--fill {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
}

.addContent__content--label {
  background-color: #fff !important;
  padding-left: 0rem !important;
  font-size: 12px !important;
  color: #000 !important;
}

.addContent__content--folder {
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.12); */

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-align: left;
  width: 100%;
  cursor: pointer;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.addContent__content--folder--input {
  color: grey;
  height: 3.5rem;
  font-size: 16px;
  border-radius: 4px;
  width: 100%;
  padding: 0 1rem;
  border: none;
  -webkit-box-flex: 2;
  -ms-flex-positive: 2;
  flex-grow: 2;
  background-color: #fff;
}

.addContent__content--folder--input:focus {
  outline: none;
}

.addContent__content--video {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.addContent__content--actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}

.addContent__content--details {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-left: 1.3rem;
}

.addContent__content--details--sub {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  /* This bit draws the box around it */
  border: 1px solid #dbdbdb;
  /* I've used padding so you can see the edges of the elements. */
  padding: 2px;
  background-color: #fff;
}

.addContent__content--details--heading {
  font-size: 16px;
  font-weight: 500;
  padding: 0;
  margin: 0;
  text-align: left;
}

.addContent__content--details--content {
  font-size: 12px;
  margin-top: 6px;
  margin-bottom: 0;
  text-align: left;
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 360px;
}

.addContent__content--details--content--item {
  color: rgba(0, 0, 0, 0.6);
  font-size: 12px;
}

.addContent__input--description {
  height: 90px !important;
}

.addContent__buttonContainer {
  margin-top: 2rem;
  padding: 1.5rem 0 0 0;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.addContent__block {
  margin-top: 8rem;
  margin-left: 2rem;
  text-align: left;
  width: 15rem;
  position: fixed;
  top: -2.3rem;
}

.addContent__block--properties {
  width: 15rem !important;
  padding: 1.5rem 1.5rem 1rem 1.5rem;
  -webkit-box-shadow: 0px 2px 8px rgba(151, 151, 151, 0.21708) !important;
  box-shadow: 0px 2px 8px rgba(151, 151, 151, 0.21708);
}

.addContent__block--header {
  font-weight: 500;
  font-size: 1.5rem;
}

.addContent__block--item {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  cursor: pointer;
  padding: 1rem 0.6rem !important;
}

.addContent__block--item:hover {
  background-color: #f5f5f5 !important;
}

.addContent__block--image {
  margin-right: 1rem !important;
}

.addContent__SVGClose {
  float: right;
  cursor: pointer;
}

.flexrow {
  display: flex;
}

.header {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1;
  background: #ffffff;
  padding: 16px;
  font-size: 16px;
  line-height: 19px;

  color: rgba(0, 0, 0, 0.87);
}

.backBtn {
  background: inherit;
  border: none;
  outline: none;
  padding: 0;
}

.maxwidth85 {
  max-width: 90%;
}

.IframePlay {
  position: relative;
}

.IframePlaySvg {
  position: absolute;

  left: 3rem;
  top: 2rem;
  width: 32px;
  height: 32px;
}

.IframePlaySvg2 {
  position: absolute;

  left: 40%;
  top: 36%;
  width: 32px;
  height: 32px;
}

.iframeContent {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  display: grid;
  place-items: center;
  background: rgba(0, 0, 0, 0.12);
  z-index: 1000;
}

.videoTime {
  position: absolute;
  right: 6px;
  bottom: 9px;
  background-color: black;
  color: white;
  font-size: 11px;
  font-weight: 700;
  padding: 0px 3px;
}

.storeDocImage {
  width: 90px;
  height: 60px;
}

.storeVideoImage {
  width: 120px !important;
  height: 90px !important;
}

@media (max-width: 600px) {
  .IframePlaySvg2 {
    position: absolute;

    left: 40%;
    top: 33%;
    width: 32px;
    height: 32px;
  }

  body .jw-error,
  body .jwplayer.jw-state-error {
    width: 320px !important;
    height: 180px !important;
  }
}

.jwplayer {
  z-index: 500;
}

.lockdoc {
  position: absolute;
  top: 30%;
  width: 32px;
}

@media (max-width: 1000px) {
  .jwplayer {
    width: 80vw !important;
    height: 35vh !important;
  }

  .addContent__content--details--content {
    max-width: 270px;
  }

  .AppContainer {
    margin: 0px;
  }

  .addContent__content--details {
    margin-left: 12px;
  }

  .addContent__content--details--heading {
    font-weight: 450;
    font-size: 14px;
    line-height: 20px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .storeVideoImage {
    width: 100px !important;
    height: 75px !important;
    border-radius: 4px;
  }

  .IframePlaySvg {
    position: absolute;

    left: 2rem;
    top: 1.25rem;
    width: 32px;
    height: 32px;
  }

  .storeDocImage {
    width: 45px;
    height: 45px;
  }

  .videoTime {
    font-size: 10px;
  }
}

@media (max-width: 420px) {
  .addContent__content--details--content {
    max-width: 230px;
  }
}

@media (max-width: 375px) {
  .addContent__content--details--content {
    max-width: 180px;
  }
}

@media (max-width: 330px) {
  .addContent__content--details--content {
    max-width: 150px;
  }
}
