.container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    margin-top: 8px;
    /* display: grid;
    grid-template-columns: repeat(4, 1fr); */
    display: flex;
    justify-content: space-around;
}


.items {
    padding: 16px;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;

    text-align: center;
    justify-content: center;
    color: rgba(0, 0, 0, 0.87);

}

.itemImg {
    margin-right: 8px;
}

.itemsTextContent {
    display: flex;
    flex-direction: column;
    align-items: center;
}

@media(max-width:1000px) {
    .container {
        border-top: 1px solid #e5e5e5;
        justify-content: space-around;
        margin-top: 0px;
        padding-top: 24px;
        /* grid-column-gap: 10px; */
    }

    .items {
        flex-direction: column;
        /* padding: 16px 16px 0px 16px; */
        padding: 0px;
    }

    .itemImg {
        margin-right: 0px;
        margin-bottom: 6px;
    }
}

@media(max-width:330px) {
    .items {
        padding: 0px 8px;
    }
}