.SidePanel {
  /* display: flex;
    flex-direction: column;
    width:414px;
    height:370px;
    margin:0 135px 0 32px;  
    background: #FFFFFF;
    box-shadow: 0px 2px 10px rgba(151, 151, 151, 0.21708);   */
}

.SidePanelHeading {
  cursor: default;
  display: flex;
  align-items: center;
  color: #000000;
  mix-blend-mode: normal;
  opacity: 0.87;
  padding: 0 0 0 16px;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
}

.SidePanelForm {
  /* padding: 12px 24px 0 24px; */
  /* border-top: 1px solid #eeeeee; */
  margin-top: 60px;
}

.SidePanelFormHeader {
  cursor: default;
  background: #ffffff;
  display: flex;
  position: absolute;
  width: 488px;
  top: 0;
  justify-content: space-between;

  align-items: center;

  border-bottom: 1px solid #e5e5e5;
  padding: 26px 0 16px 10px;

  border-radius: 12px 12px 0 0;
  z-index: 10;
}

.SidePanelFormBackBtn {
  /* position: absolute;
    left: 16px;
    top: 12px; */
  margin-left: 16px;
  background-color: white;
  outline: none;
  border: none;
  cursor: pointer;
}

.SidePanelBackButton {
  background: none;
  border: none;
}

.SidePanelFormHeading {
  text-align: start;
  margin-left: 10px;
  width: 370px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  /* padding-left: 24px; */
}

.SidePanelFormDetails {
  background: #f1f7ff;
  border: 0.5px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 56px 40px 16px 24px;
  display: flex;
  padding: 12px;
}

.SidePanelFormDetailsContent {
  display: flex;
  flex-direction: column;
  margin-left: 14px;
}

.SidePanelFormDetailsContentRow1 {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.87);
}

.SidePanelFormDetailsContentRow2 {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 150%;
  margin-top: 4px;

  color: rgba(0, 0, 0, 0.6);
}

.SidePanelFormRowInput {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 2px;
  height: 20px;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.4);
  padding: 10 0 0 16px;
  width: 100%;
}

.SidePanelDropdown {
  height: 30px;
  font-size: 14px;
  line-height: 17px;
}

.DownloadLink {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: initial;
}

.CountryExtensionContainer {
  display: flex;
  /* margin-bottom: 24px; */
  justify-content: space-between;
  align-items: center;
}

.extension {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 10px;
}

.SelectStateMobile {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 2px;
  height: 38px;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.4);
  padding: 0 0 0 16px;
  width: 100%;
  flex-shrink: 0;
  outline: none;
}

.SelectStateMobileImage {
  position: absolute;
  top: 10px;
  right: 20px;
}

.SubmitButtonContainer {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.24);
  padding: 12px 24px 0px 24px;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* margin-top: 25px; */
}

.SubmitButtonOtpContainer {
  background: #ffffff;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.24);
  padding: 12px 24px 0px 24px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
}

.SubmitButtonContainerText {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  padding: 8px 0px;
}

.SubmitButtonOtpContainerText {
  display: flex;
  justify-content: center;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 21px;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  padding: 8px 0px;
}

.SubmitButton {
  background: #009ae0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-content: flex-end; */
  width: 350px;
  height: 44px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  border: none;
  width: 35%;
  margin-left: auto;
}

.SubmitButtonOtp {
  background: #009ae0;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* align-content: flex-end; */
  width: 350px;
  height: 44px;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  cursor: pointer;
  outline: none;
  border: none;
  width: 35%;
  margin-left: auto;
}

.disableButton {
  opacity: 0.6;
  cursor: not-allowed;
}

.SubmitButton:disabled {
  background: #009ae0;
  cursor: not-allowed;
}

.panel {
  color: red;
  margin: 16px;
}

.OtpModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.OtpModal_heading {
  color: rgba(0, 0, 0, 0.87);
  font-size: 24px;
  line-height: 36px;
  font-weight: 400;
}

.OtpModal_text {
  font-size: 18px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.6);
  margin: 0;
}

.OtpModal_input {
  border: 1px solid #c5c5c5;
  border-radius: 4px;
  padding: 12px 16px;
  color: rgba(0, 0, 0, 0.6);
  font-size: 16px;
  line-height: 24px;
  margin: 0 0 24px 0;
  text-align: center;
}

.OtpModal_button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.OtpModal_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #009ae0;
  border: 1px solid #009ae0;
  letter-spacing: 1.2px;
  color: #fff;
  border-radius: 2px;
  box-shadow: none;
  outline: none;
  width: 40%;
  /* width: 350px; */
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 12px 0;
  /* margin: 0 0 24px 0; */
  cursor: pointer;
}

.OtpModal_mobileNumber {
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #009ae0;
  margin-top: 0;
}

.DownloadModal_content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
}

.DownloadModal_heading {
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-bottom: 0;
  color: rgba(0, 0, 0, 0.87);
}

.DownloadModal_text {
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  display: flex;
  align-items: center;
  text-align: center;
  word-break: break-word;
  color: rgba(0, 0, 0, 0.6);
  padding: 0px 16px;
}

.DownloadModal_button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #009ae0;
  border: 1px solid #009ae0;
  letter-spacing: 1.2px;
  color: #fff;
  border-radius: 2px;
  box-shadow: none;
  outline: none;
  /* width: 350px; */
  width: 73%;
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 12px 0;
  margin: 0 0 24px 0;
  cursor: pointer;
}

.DownloadModal_ReceiptButton {
  border: 1px solid #00a9e0;
  box-sizing: border-box;
  border-radius: 4px;
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;

  letter-spacing: 1.2px;
  color: #fff;
  border-radius: 2px;
  box-shadow: none;
  outline: none;
  width: 73%;
  /* width: 350px; */
  height: 48px;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  padding: 12px 0;
  margin: 0 0 24px 0;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #009ae0;
}

.Hyphen {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;
}

.ModalPriceDetails {
  padding: 24px 0px;
  margin: 0px 24px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid #e5e5e5;
  /* margin-bottom: 60px; */
}

.ModalPriceDetailsHeading {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
  mix-blend-mode: normal;
  margin-bottom: 10px;
}

.ModalPriceDetailsItem {
  padding: 6px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.ModalPriceDetailsItemKey {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  opacity: 0.87;
}

.ModalPriceDetailsItemValue {
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  opacity: 0.87;
}

.ModalPriceDetailsItemTotal {
  padding: 12px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e5e5e5;
}

.ModalPriceDetailsItemTotalKey {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 22px;
  color: #000000;
  opacity: 0.87;
}

.ModalPriceDetailsItemTotalValue {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  opacity: 0.87;
}

/***************************OtpModalContainer *****************/
.OtpModalContainer {
  display: flex;
  flex-direction: column;
}

.OtpModalContainerHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  padding-top: 6px;
  padding-bottom: 16px;
  padding-left: 16px;
  border-bottom: 1px solid #e5e5e5;
}

.OtpModalContentContainer {
  /* margin: 24px; */
  display: flex;
  flex-direction: column;
}

.OtpModalContentText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.87);
  mix-blend-mode: normal;
  margin-top: 24px;
  margin-left: 24px;
}

.OtpModalContentMobileField {
  display: flex;
  justify-content: space-between;
  /* margin-top: 8px; */
  background: #f0f4f7;
  border-radius: 4px;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  margin: 8px 24px 0px 24px;
}

.OtpModalContentMobile {
  display: flex;
}

.OtpModalContentMobileCode {
  padding: 14px 16px;
  border-right: 1px solid #c4cdd5;
}

.OtpModalContentMobileNumber {
  padding: 14px 16px;
}

.OtpModalContentMobileNumberBtn {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  text-align: right;
  text-decoration-line: underline;
  cursor: pointer;
  color: #009ae0;
  background: #f0f4f7;
  padding-right: 24px;
}

.EnterOtpText {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-top: 24px;
  margin-bottom: 8px;
  color: rgba(0, 0, 0, 0.87);
  mix-blend-mode: normal;
  margin-left: 24px;
}

/* .otpField {
    margin-top: 8px;
    display: flex;
    flex-direction: row;
    margin-left: 24px;
  } */
.waitingForOtp {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  margin-top: 32px;
  margin-bottom: 100px;
  display: flex;
  align-items: center;
  margin-left: 24px;
  color: rgba(0, 0, 0, 0.87);
}

.OtpModalFooter {
  padding-top: 16px;
  border-top: 1px solid #e5e5e5;
  display: flex;
}

.OtpModalFooterBtn {
  background: #009ae0;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 35%;
  margin-left: auto;
  padding: 8px 0px;
  color: #ffffff;
  border: none;
  margin-right: 24px;
}

.selectStateModal {
  display: flex;
  flex-direction: column;
  background-color: white;
  height: 90vh;
}

.selectStateModalHeading {
  /* margin-top: 12px; */
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.87);
  mix-blend-mode: normal;
  padding: 14px 24px 24px 24px;
  border-bottom: 1px solid #e5e5e5;
  background-color: white;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  /* position: fixed;
    width: 100%; */
}

.selectStateModalContent {
  padding: 16px 24px;
  margin-bottom: 32px;
  /* margin: 16px 24px; */
  /* margin: 16px 0px; */
  display: flex;
  flex-direction: column;
  min-height: 80vh;
  max-height: 80vh;
  overflow-y: scroll;
  /* margin-top: 54px; */
}

.selectStateModalContentTopText {
  /* margin-top: 60px; */
  /* margin: 60px 24px 0px 24px; */
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: rgba(0, 0, 0, 0.87);
  mix-blend-mode: normal;
}

.selectStateModalContentInputField {
  position: relative;
  margin-top: 16px;
  /* margin: 16px 24px 0px 24px; */
}

.selectStateModalContentInputBox {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 14px 16px;
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  outline: none;
  z-index: -1;
}

.selectStateModalContentInputImg {
  position: absolute;
  top: 16px;
  right: 16px;
}

.selectStateModalCityListHeading {
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 15px;
  text-transform: uppercase;
  margin-top: 24px;
  /* margin: 24px 24px 0px 24px; */
  color: rgba(0, 0, 0, 0.5);

  mix-blend-mode: normal;
  /* opacity: 0.6; */
}

.selectStateModalCityLists {
  display: flex;
  flex-direction: column;
  margin: 6px 0px 32px 0px;
  /* padding: 6px 24px 32px 24px;
    min-height: 80vh;
    max-height: 80vh;
    overflow-y: scroll; */
}

.selectStateModalCityItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0px;
}

.selectStateModalCityItemName {
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  color: #282828;
}

.selectStateModalFooter {
  position: fixed;
  bottom: 0;
  display: flex;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px -1px 4px rgba(0, 0, 0, 0.12);
  width: 500px;
  margin: auto;
}

.selectStateModalBtn {
  cursor: pointer;
  background: #009ae0;
  box-shadow: 0px 1px 4px rgba(0, 169, 224, 0.24);
  border-radius: 4px;
  outline: none;
  border: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  padding: 12px 0px;
  width: 100%;
  display: flex;
  align-items: center;
  text-align: right;
  display: flex;
  justify-content: center;
  color: #ffffff;
}

.selectStateModalMain {
  min-height: 90% !important;
}

/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
.Partition {
  display: block;
  border-bottom: 1px solid #e5e5e5;
  width: 90%;
  margin: 0 auto;
}

.AppliedCoupon {
  /* margin: 16px auto; */
  padding: 16px 24px;
}

.AppliedCouponHeading {
  cursor: default;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  margin-bottom: 16px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.87);
}

.CouponSuccessful {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.AppliedCouponDetails {
  display: flex;
  flex-direction: column;
}

.AppliedCouponCode {
  cursor: default;
  margin-bottom: 5px;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.87);
}

.AppliedCouponCode > img {
  margin-right: 10px;
}

.AppliedCouponLabel {
  cursor: default;
  margin-left: 28px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.6);
}

.SelectACoupon {
  cursor: default;
  display: flex;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height */

  color: rgba(0, 0, 0, 0.87);
}

.SelectACoupon > img {
  margin-right: 10px;
}

.RemoveCoupon {
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;

  color: #ff4058;
}

.ViewAllButton {
  cursor: pointer;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: right;
  text-transform: uppercase;

  color: #009ae0;
}

/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */
/* APPLIEDCOUPONSECTION */

@media (max-width: 1000px) {
  .selectStateModalMain {
    min-height: 90% !important;
  }

  .SidePanelFormContents {
    min-height: 80vh;
    max-height: 80vh;
    overflow-y: scroll;
    margin-top: 40px;
  }

  .FormModal {
    height: 90vh;
    /* height: 100vh; */
  }

  .SidePanel {
    /* margin: 8px 0 0 0;
        width: auto; */
  }

  .SidePanelFormDetails {
    /* margin-top: 90px; */
    margin-top: 30px;
  }

  .SidePanelForm {
    padding: 0px 0px 32px 0px;
    margin: 0px;
    /* border-top: 1px solid #eeeeee; */
    /* height: 90vh; */
  }

  .SubmitButtonContainer {
    position: fixed;
    bottom: 0;
    width: 100%;
    padding: 0px 16px 12px 16px;
  }

  .SubmitButtonOtpContainer {
    position: fixed;
    bottom: 0;
    width: 98%;
    padding: 0px 16px 12px 0px;
    align-items: center;
  }

  .disableButton {
    opacity: 0.6;
    cursor: pointer;
  }

  .SubmitButton {
    width: 94%;
    margin-right: 24px;
    align-items: center;
    justify-content: center;
  }

  .ModalPriceDetails {
    margin-bottom: 60px;
  }

  .OtpModalFooterBtn {
    width: 94%;
    margin-left: 24px;
  }

  .OtpModalContainerHeading {
    padding-top: 10px;
    font-size: 16px;
  }

  .DownloadModal_button {
    width: 100%;
    margin: 0px 16px;
  }

  .DownloadModal_ReceiptButton {
    width: 92%;
    margin: 24px 16px;
  }

  .SidePanelFormHeader {
    /* position: unset; */
    /* width: 100%;
        top: 10%; */
    /* z-index: 1; */
    width: 95%;
    padding: 10px 0 16px 10px;
    background: white;
    /* background: transparent; */
  }

  .selectStateModalHeading {
    /* position: fixed;
        width: 100%;
        top: 100px; */
  }

  .selectStateModalFooter {
    width: 92%;
  }

  .OtpModal_button {
    width: 92%;
    margin-bottom: 0px;
  }

  .selectStateModalCityLists {
    margin: 6px 0px 52px 0px;
    /* padding: 6px 24px 52px 24px; */
  }
}

@media (max-width: 430px) {
  .SidePanelFormHeader {
    /* position: unset; */
    /* width: 100%;
        top: 80px; */
    width: 100%;
    padding-top: 8px;
    /* background: transparent; */
    background: white;
    /* border-top-right-radius: 45px; */
  }

  .SidePanelFormDetails {
    /* margin-top: 75px; */
    margin-top: 30px;
  }

  .selectStateModalHeading {
    /* position: fixed;
        width: 100%;
        top: 80px; */
  }

  .SidePanelForm {
    /* padding: 0px 0px 32px 0px; */
    margin: 0px;
    /* border-top: 1px solid #eeeeee; */
    /* height: 90vh; */
  }
}

@media (max-width: 400px) {
  .SidePanelFormHeader {
    padding-top: 20px;
  }
}

@media (max-width: 370px) {
  .SubmitButtonContainer {
    width: 96.5%;
  }

  .SidePanelForm {
    /* padding: 0px 0px 32px 0px; */
    margin: 0px;
    /* border-top: 1px solid #eeeeee; */
    /* height: 90vh; */
  }

  /* .SubmitButtonOtpContainer {
       
        width: 96.5%;
        
    } */
  .SidePanelFormHeader {
    /* position: unset; */
    /* width: 100%;
        top: 80px; */
    width: 95%;
    padding-top: 26px;
  }

  .selectStateModalHeading {
    /* position: fixed;
        width: 100%;
        top: 78px; */
  }
}

@media (max-width: 330px) {
  .SubmitButtonContainer {
    width: 95%;
  }

  .selectStateModalCityLists {
    margin: 6px 0px 42px 0px;
    /* padding: 6px 16px 42px 16px; */
  }

  .SidePanelFormHeader {
    /* position: unset; */
    /* width: 100%;
        top: 80px; */
    width: 100%;
    padding-top: 26px;
  }

  .selectStateModalHeading {
    /* position: fixed;
        width: 100%;
        top: 80px; */
  }

  .SidePanelFormDetails {
    /* margin-top: 68px; */
    margin-top: 30px;
  }

  .SidePanelForm {
    /* padding: 0px 0px 32px 0px; */
    margin: 0px;
    /* border-top: 1px solid #eeeeee; */
    /* height: 90vh; */
  }
}

.divFlex {
  display: flex;
  flex-direction: column;
  padding: 0px 24px;
}

.CountryMobileExtension {
  width: 100%;
  text-align: center;
  border: 1px solid #e5e5e5;
  font-size: 14px;
  line-height: 17px;
  color: rgba(0, 0, 0, 0.4);
  /* height: 34px; */
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 0 10px;
  border-radius: 2px;
  margin-bottom: 2px;
}

.divFlex div {
  /* margin: 0.5rem; */
}
