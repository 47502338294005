.container {
    display: flex;
    flex-direction: column;
}

.heading {
    padding: 24px 24px 16px 24px;
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    /* identical to box height, or 27px */


    color: rgba(0, 0, 0, 0.87);
}

@media(max-width:700px){
    .heading{
        padding: 24px 16px 16px 16px;
        font-size: 16px;
        border-top: 7px solid #e5e5e5;
    }
}