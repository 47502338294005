.container {
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  /* width: 22.4%; */
  position: fixed;
  width: auto;
  margin-right: 150px;
  /* max-width: 320px; */
}

.imageContainer {
  display: flex;
  /* display: grid;
  grid-template-columns: 1fr; */
  position: relative;
  max-width: 100%;
}

.image {
  /* width: 320px; */
  height: 240px;
  max-width: 100%;
  width: 100%;
}

.topSellingTag {
  font-size: 14px;
  line-height: 150%;
  background: #ff4058;
  border-radius: 0px 4px 4px 0px;
  /* padding: 12px 6px; */
  padding: 5px 6px;
  display: flex;
  align-items: center;
  position: absolute;
  top: 20px;
  left: 0px;
  color: #ffffff;
}

.preview {
  position: absolute;
  bottom: 0px;
  display: none;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  align-items: center;
  /* background: rgba(0, 65, 135, 0.8); */
  background: rgba(0, 0, 0, 0.65);
  border-radius: 0px 0px 4px 4px;
  padding: 12px 0;
  width: 100%;
  justify-content: center;
  color: #ffffff;
  outline: none;
  border: none;
}

.mobileHeading {
  display: none;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.87);
  margin: 0 16px;
}

.shareBtn {
  border: none;
  background: inherit;
  outline: none;
  padding: 0;
  margin-left: 6px;
}

.pricing {
  margin: 16px;
  display: flex;
  /* justify-content: space-between;
    width: 70%; */
  /* width: 100%; */
}

.cost {
  font-style: normal;
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.87);
}

.discValue {
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 21px;
  display: flex;
  align-items: center;
  text-decoration-line: line-through;
  margin-right: 20px;
  color: rgba(0, 0, 0, 0.6);
}

.discount {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */
  /* margin-right: 20px; */
  display: flex;
  align-items: center;

  color: #ff4058;
}

.CouponsAvailable {
  display: flex;
  align-items: center;
  margin: 8px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height */

  color: #009ae0;
}

.CouponsAvailable > img {
  margin-right: 5px;
}

.courseStatsMobile {
  display: none;
}

.stickpoint {
  padding: 4px 16px;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 150%;

  display: flex;
  /* align-items: center; */

  color: rgba(0, 0, 0, 0.87);
}

.buyBtn {
  margin-top: 8px;
  background: #009ae0;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  border: none;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 14px 0;
  color: #ffffff;
  width: 90%;
  align-self: center;
  cursor: pointer;
}

.secure {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 21px;
  align-self: center;
  display: flex;
  align-items: center;
  padding: 12px 0 16px 0;
  color: rgba(0, 0, 0, 0.6);
  /* border-bottom: 1px solid #E5E5E5; */
}

.contentSummaryDesktop {
  display: flex;
}

@media (min-width: 1100px) and (max-width: 1420px) {
  .pricing {
    margin: 10px;
  }
  .cost {
    font-size: 17px;
    margin-right: 12px;
    line-height: 19px;
  }
  .discValue {
    font-size: 17px;
    margin-right: 12px;
    line-height: 19px;
  }
  .discount {
    font-size: 15px;
    line-height: 17px;
  }
  .buyBtn {
    padding: 10px 0;
  }
  .stickpoint {
    padding: 4px 12px;
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 120%;
  }
  .secure {
    padding: 10px 0 13px 0;
    font-size: 13px;
    line-height: 18px;
  }
}

@media (max-width: 1000px) {
  .image {
    width: 100% !important;
    height: 240px !important;
    max-width: 100%;
  }

  .container {
    margin-top: 0px;
    border: none;
    width: 100%;
    position: static;
    max-width: 100%;
    box-shadow: none;
    padding: 16px 16px 0 16px;
  }

  .pricing {
    margin: 16px 0;
    display: flex;
    /* justify-content: space-between; */
    /* width: 70%; */
  }

  .cost {
    font-size: 18px;
    margin-right: 16px;
  }

  .discValue {
    font-size: 16px;
    margin-right: 16px;
  }

  .discount {
    font-size: 14px;
    /* margin-right: 16px; */
  }

  .imageContainer {
    margin: 0 0 16px 0;
  }

  .image {
  }

  .preview {
    display: flex;
  }

  .mobileHeading {
    display: flex;
    justify-content: space-between;
    margin: 0;
    align-items: flex-start;
  }

  .courseStatsMobile {
    display: flex;
    margin: 0 0 24px 0;
  }

  .stickpoint {
    display: none;
  }

  .buyBtn {
    display: none;
  }

  .secure {
    display: none;
  }

  .contentSummaryDesktop {
    display: none;
  }

  .CouponsAvailable {
    margin: 8px 0px 16px;
  }
}

@media (min-width: 1600px) and (max-width: 2000px) {
  .container {
    /* max-width: 100%; */
  }

  .image {
    width: 100%;
  }
}
