.container {
  background: linear-gradient(
      89.92deg,
      #f3faff 0.07%,
      rgba(238, 247, 254, 0) 99.93%
    ),
    #fcfcfc;
  padding: 24px 32px 0 150px;
  /* display: flex; */
  flex-direction: column;
}

.courseName {
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 150%;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.showBorderClass {
  border-top: 1px solid #e5e5e5 !important;
  margin-top: 12px;
}

.shareButton {
  border: 1px solid #009ae0;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  padding: 6px 8px;
  color: #009ae0;
  cursor: pointer;
  background: none;
  outline: none;
}

.creator {
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid #e5e5e5;
  padding: 16px 0;
  background: inherit;
  border-top: none;
  border-left: none;
  border-right: none;
  outline: none;
  cursor: pointer;
}

.creatorName {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;

  color: #009ae0;
}

.list {
  padding: 12px 0 0 0;
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  color: rgba(0, 0, 0, 0.87);
  align-items: center;
}

/* 
.items {
    margin-top: 18px;
    border-top: 1px solid #E5E5E5;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    display: flex;
    align-items: center;
    text-align: center;

    color: rgba(0, 0, 0, 0.87);

} */

@media (max-width: 1000px) {
  /* .container {
        display: none;
    } */
}
