.StudentViewedDesktop {
  margin: 24px;
  display: flex;
  flex-direction: column;
}

.StudentViewedHead {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.StudentViewedHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.87);

}

.StudentViewedbtn {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  outline: none;
  background-color: white;
  text-align: right;
  border: none;
  color: #009AE0;
  cursor: pointer;
}

.StudentViewedCourseList {
  display: flex;
}

/* .StudentViewedCourseCarousel {
   
        display: flex !important;
        flex-direction: row;
        justify-content: space-between;
        
        border-radius: 4px;
     
} */

.StudentViewedCourse {
  display: flex;
  flex-direction: column;
  /* margin-top: 24px;
  margin-right: 24px; */
  width: 262px;
  background: #FFFFFF;
  border: 1px solid #E5E5E5;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  cursor: pointer;

}

.StudentViewedCourseImg {
  width: 260px;
  /* height: 200px; */
  height: 195px;
  border: none;
}

.StudentViewedCourseContent {
  padding: 16px;
  display: flex;
  flex-direction: column;


}

ul {
  margin-top: 0px !important;
}

li {
  margin: 0 !important;
  margin-right: 24px !important;
}

.StudentViewedCourseContentRow1 {
  font-style: normal;
  font-weight: 450;
  font-size: 16px;
  line-height: 24px;
  color: rgba(0, 0, 0, 0.87);
  text-align: start;
  width: 230px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.StudentViewedCourseContentRow2 {
  display: flex;
  margin-top: 16px;
  align-items: center;
}

.StudentViewedCoursePrice {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.87);

  mix-blend-mode: normal;
}

.StudentViewedCourseDiscount {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-left: 16px;

  display: flex;
  align-items: center;
  text-decoration-line: line-through;

  color: rgba(0, 0, 0, 0.6);

  mix-blend-mode: normal;
}

.StudentViewedCourseOffer {
  margin-left: 16px;
  font-style: normal;
  font-weight: normal;
  font-size: 10px;
  line-height: 12px;
  /* identical to box height */

  display: flex;
  align-items: center;

  color: #FF4058;

}

/* .StudentViewedCourse>.slideShow .slickSlideClass,
.slideShow .slickSlideClass .single-item {
  width: 100%;
}

.StudentViewedCourse>.slick-dots {
  bottom: -50px !important;
}






.slideShow .slickSlideClass .single-item img {
  width: 100%;
  height: auto;
}

.slick-arrow {
  z-index: 10;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.slick-arrow::before {
  font-size: 28px;
}

.slick-prev {
  left: 4px;
}

.slick-next {
  right: 4px;
}


  .slick-dots {
      bottom: -4px !important;
  }

.slick-dots li button:before {
  color: #fff;
  font-size: 8px;
}

.slick-dots li.slick-active button:before {
  color: #fff;
  font-size: 12px;
}


.slideShow .slickSlideClass .single-item img {
  width: 100%;
  height: auto;
}

.slick-arrow {
  z-index: 10;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}

.slick-arrow::before {
  font-size: 28px;
}

.slick-prev {
  left: 4px;
}

.slick-next {
  right: 4px;
}



.slick-dots li button:before {
  color: #fff;
  font-size: 8px;
}

.slick-dots li.slick-active button:before {
  color: #fff;
  font-size: 12px;
} */


@media(max-width:1000px) {
  .StudentViewedDesktop {
    display: none;
  }

}