html {
  scroll-behavior: smooth;

}

body {
  margin: 0;
  font-family: "Circular Std",
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}