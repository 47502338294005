.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  border-top: 1px solid #e5e5e5;
  /* margin-top: 18px; */
}

.heading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  margin: 0;
  margin: 16px 0 0 16px;
  /* padding: 16px 0 0 16px; */
  color: rgba(0, 0, 0, 0.87);
}

.content {
  display: grid;
  margin: 16px 16px;
  grid-template-columns: repeat(3, 1fr);
}

.items {
  width: 100%;
  /* padding: 8px 0 0 0; */

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 150%;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: flex-start;
  color: rgba(0, 0, 0, 0.87);
}

.itemsImage {
  margin-right: 8px;
}

.itemTexts {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
  /* or 150% */

  display: flex;
  /* align-items: center; */

  color: rgba(0, 0, 0, 0.87);

  mix-blend-mode: normal;
}

@media (min-width: 1100px) and (max-width: 1420px){
  .content {
    margin: 8px;
  }
  .heading {
    margin: 8px 0 0 8px;
    font-size: 14px;
  }
  .itemsImage {
    width: 35px;
    height: 35px;
    margin-right: 4px;
  }
  .itemTexts {
    font-size: 14px;
    line-height: 20px;
  }
}


@media (max-width: 1000px) {
  .heading {
    /* padding: 24px 0 0 16px; */
    margin: 24px 0 0 16px;
  }

  .content {
    /* padding: 0px 16px 16px 16px; */
    padding: 16px 16px 16px 16px;
    margin: 0;
    border-bottom: 1px solid #e5e5e5;
    grid-column-gap: 10px;
  }

  .items {
    padding: 0;
  }
}