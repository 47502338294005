.PreviewCourse {
  display: flex;
  flex-direction: column;
  background: #ffffff;
  margin: 20px 16px 0px;
  /* padding: 0px 16px; */
  /* border-bottom: 1px solid rgba(229, 229, 229, 0.6); */
  /* padding-bottom: 24px; */
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  position: fixed;
  top: 0px;
  width: 100%;
  z-index: 1;
  background: #ffffff;
  padding: 16px;
  font-size: 16px;
  line-height: 19px;

  color: rgba(0, 0, 0, 0.87);
}

.backBtn {
  background: inherit;
  border: none;
  outline: none;
  padding: 0;
}

.PreviewCourseHeading {
  /* padding-top: 8px; */
  /* padding-bottom: 6px; */
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;

  color: #000000;
  opacity: 0.87;
}

.PreviewCourseList {
  /* display: flex;
    flex-direction: column; */
  display: grid;
  grid-template-columns: 1fr;
  margin-top: 10px;
  padding-bottom: 10px;
  border-bottom: 1px dashed #cccccc;
}

.PreviewCourseItem {
  display: flex;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  margin: 6px 0px;
  height: 92px;
}

.PreviewCourseItemImage {
  position: relative;
  display: flex;
  justify-content: center;
  max-height: 120px;
}

.PreviewCourseItemImg {
  /* height: 100%; */
}

.PreviewCourseItemImageTime {
  position: absolute;
  display: flex;
  bottom: 8px;
  right: 8px;
  background: rgba(0, 0, 0, 0.6);
  border-radius: 2px;
  padding: 2px 4px;
}

.PreviewCourseLockImage {
  position: absolute;
  display: flex;
  bottom: 30%;
  right: 43%;
  /* left:40%;
  top:33%; */
  /* background: rgba(0, 0, 0, 0.6);
    border-radius: 2px; */
  padding: 2px 4px;
}

.PreviewCourseItemImageTime label {
  font-style: normal;
  font-weight: 450;
  font-size: 12px;
  line-height: 12px;
  display: flex;
  align-items: center;
  color: #ffffff;
  mix-blend-mode: normal;
  margin-left: 4px;
}

.PreviewCourseItemContent {
  display: flex;
  flex-direction: column;
  margin: 12px 24px 12px 12px;
}

.PreviewCourseItemContentRow1 {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 150%;
  color: rgba(0, 0, 0, 0.87);
  word-break: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.PreviewCourseItemContentRow2 {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 18px;
  overflow: t st;
  margin-top: 6px;
  color: rgba(0, 0, 0, 0.87);
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.KeyText {
  color: rgba(0, 0, 0, 0.6);
}

.PreviewCourseFooter {
  background: #ffffff;
  border: 1px solid #009ae0;
  box-sizing: border-box;
  border-radius: 4px;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding: 12px 0px;
  color: #009ae0;
  margin-top: 16px;
  outline: none;
  cursor: pointer;
  margin-bottom: 0;
}

.videoCrossImg {
  position: absolute;
  /* top: -5%; */
  right: 0%;
  bottom: 104%;
  cursor: pointer;
}

@media (max-width: 700px) {
  .PreviewCourseLockImage {
    left: 38%;
    top: 33%;
    padding: 0px;
  }

  .PreviewCourseItemImage {
    width: 162px;
  }

  .PreviewCourseItemImg {
    width: 162px;
    /* width: 60px */
  }

  .PreviewCourseLockedImg {
    width: 162px;
  }

  .videoCrossImg {
    position: absolute;
    /* top: -7%; */
    right: 0%;
    bottom: 105%;
    cursor: pointer;
  }
}

@media (max-width: 420px) {
  .PreviewCourseItem {
    display: flex;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin: 6px 0px;
    height: 85px;
  }

  .PreviewCourseItemImage {
    width: 150px;
  }

  .PreviewCourseItemImg {
    width: 150px;
    /* width: 60px; */
  }

  .PreviewCourseLockedImg {
    width: 150px;
  }

  .PreviewCourseItemContent {
    display: flex;
    flex-direction: column;
    margin: 12px;
  }

  .PreviewCourseItemContentRow1 {
    font-size: 13px;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .PreviewCourseItemContentRow2 {
    font-size: 13px;
  }
}

@media (max-width: 375px) {
  .PreviewCourseItem {
    display: flex;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin: 6px 0px;
    height: 80px;
  }

  .PreviewCourseItemImage {
    width: 140px;
  }

  .PreviewCourseItemImg {
    width: 140px;
    /* width: 60px; */
  }

  .PreviewCourseLockedImg {
    width: 140px;
  }

  .PreviewCourseItemContent {
    display: flex;
    flex-direction: column;
    margin: 8px;
  }

  .PreviewCourseItemContentRow1 {
    font-size: 12px;
  }

  .PreviewCourseItemContentRow2 {
    font-size: 12px;
  }
}

@media (max-width: 325px) {
  .PreviewCourseItem {
    display: flex;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin: 6px 0px;
    height: 68px;
  }

  .PreviewCourseItemImage {
    width: 120px;
  }

  .PreviewCourseItemImg {
    width: 120px;

  }

  /*.PreviewCourseLockedImg {
        width: 120px
    } */

  .PreviewCourseItemContent {
    display: flex;
    flex-direction: column;
    margin: 6px;
  }

  .PreviewCourseItemContentRow1 {
    font-size: 11px;
  }

  .PreviewCourseItemContentRow2 {
    font-size: 11px;
  }
}

@media (max-width: 1000px) {
  .PreviewCourse {
    margin: 0px;
    padding: 24px 16px 16px 16px;
  }

  .PreviewCourseHeading {
    padding-top: 0px;
  }
}

@media (min-width: 700px) {
  /* .PreviewCourse {
    margin: 16px 24px 0px 24px;
    padding: 0px 0px 16px 0px;
  } */

  .PreviewCourseHeading {
    padding-bottom: 0px;
  }

  .PreviewCourseList {
    /* display: flex;
        flex-direction: row; */
    /* display: flex;
        flex-direction: row;
        justify-content: space-between; */

    grid-template-columns: 1fr 1fr 1fr;
    grid-column-gap: 16px;
    margin-top: 8px;
    padding-bottom: 8px;
    border-bottom: 1px dashed #cccccc;
  }

  .PreviewCourseItem {
    display: flex;
    flex-direction: column;
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.12);
    border-radius: 4px;
    margin: 6px 0px;
    height: auto;
    /* width: 217px; */
    /* width: 220px; */
  }

  .PreviewCourseItemImage {
    /* width: 215px; */
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    min-height: 120px;
    height: auto;
  }

  .PreviewCourseItemImg {
    /* width: 100%;
        height: 100%; */
    width: 100%;
    max-height: 100%;
    /* width: 80px; */
    /* height: 80px; */
    /* margin-top: 16px; */
  }

  .PreviewCourseLockedImg {
    width: 100%;
    max-height: 100%;
  }

  .PreviewCourseItemContent {
    margin: 14px 16px;
  }

  .PreviewCourseFooter {
    width: 40%;
    margin: auto;
    margin-top: 16px;
    margin-bottom: 16px;
  }
}

@media (min-width: 1500px) and (max-width: 2000px) {
  .PreviewCourseItemImage {
    height: 180px;
  }
}