.WhatsIncluded {
  display: flex;
  flex-direction: column;
  margin: 24px 0px 0px 0px;
  padding: 16px;
  border-bottom: 8px solid #e5e5e5;
}

.WhatsIncludedHeading {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  padding-bottom: 8px;
  color: rgba(0, 0, 0, 0.87);
}

.WhatsIncludedTextList {
  display: grid;
  grid-template-columns: 1fr;
}

.WhatsIncludedTikText {
  margin: 8px 0px;
  display: flex;
  align-items: center;
}

.WhatsIncludedText {
  font-style: normal;
  font-weight: 450;
  font-size: 14px;
  line-height: 24px;
  margin-left: 16px;
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.87);
  mix-blend-mode: normal;
}

@media (max-width: 700px) {
  .WhatsIncluded {
    margin-top: 0px;
    padding: 24px 16px;
  }
}


@media (min-width: 700px) {

  .WhatsIncludedTextList {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-content: baseline;
    padding-bottom: 24px;
    border-bottom: 1px solid #e5e5e5;
  }

  .WhatsIncluded {
    padding: 24px 0 0 0;
    margin: 0 24px;
    border: none;
  }
}