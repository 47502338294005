.AboutCourseCreator {
    /* margin-top: 16px; */
    padding: 24px 16px 0px 16px;
    display: flex;
    flex-direction: column;
    border-bottom: 8px solid #e5e5e5;
}

.AboutCourseCreatorHeading {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;

    /* opacity: 0.87; */

}

.AboutCourseCreatorImageTextGroup {
    display: flex;
    flex-direction: column;
    padding-bottom: 24px;
}

.AboutCourseCreatorContentGroup {
    margin-left: 0px;
}

.AboutCourseCreatorImage {
    margin-top: 16px;
    height: 72px;
    width: 72px;
    border-radius: 50%;
}

.AboutCourseCreatorName {
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
    margin-top: 16px;
    color: rgba(0, 0, 0, 0.87);
}

.AboutCourseCreatorSubText {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 21px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    margin-bottom: 8px;
}

.AboutCourseCreatorImgTextGroup {
    margin: 4px 0px;
    display: flex;
    align-items: center;
}

.AboutCourseCreatorTextGroup {
    margin-left: 12px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.87);
}

.AboutCourseCreatorFooterHeading {
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    margin-top: 12px;
}

.bold {
    font-weight: bold;
}

@media(min-width:700px) {
    .AboutCourseCreatorImageTextGroup {
        display: flex;
        flex-direction: row;
        border-bottom: 1px solid rgba(229, 229, 229, 0.6);
    }

    .AboutCourseCreatorContentGroup {
        margin-left: 16px;
    }

    .AboutCourseCreator {
        padding: 24px 24px 0px 24px;
        border-bottom: none;
    }
    .AboutCourseCreatorHeading {
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.87);
    }
}