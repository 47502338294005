.container {
    margin: 0px 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid rgba(229, 229, 229, 0.6);
}

.mainContent2 {
    display: block;
    height: 5vh;
    overflow-wrap: break-word;
    /* overflow: hidden; */
}

.mainContent2 .open2 {
    height: auto;
    overflow-wrap: break-word;
    overflow: scroll;
}

.paraDesc {
    display: block;
    font-size: var(--font--size--small);
    overflow-wrap: break-word;
    width: 100vw;
    text-align: left;
    /* word-break: break-all; */
    box-sizing: border-box;
    max-width: 100%;
    height: 4.8em;
    overflow: hidden;
    /* padding: 10px; */
    display: inline-block;
    text-align: justify;
    text-justify: inter-word;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 150%;
    white-space: pre-wrap;
    /* or 24px */


    color: rgba(0, 0, 0, 0.87);
}

.paraDesc p {
    margin: 0;
    padding: 0;
}

/* .paraDesc *{
    width: 100%;
} */
.paraComplete {
    display: block;
    font-size: var(--font--size--small);
    overflow-wrap: break-word;
    width: 100vw;
    max-width: 100%;
    box-sizing: border-box;
    text-align: left;
    overflow-x: hidden;
    text-align: justify;
    text-justify: inter-word;
    height: auto;
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 150%;
    white-space: pre-wrap;
    /* or 24px */


    color: rgba(0, 0, 0, 0.87);
    /* padding: 2%; */
}

.showPara {}

.hidePara {
    display: none;
}

.mainContent2Button {
    font-size: var(--font--size--small);
}

.btnInAcc {
    padding: 0;
    font-size: var(--font--size--small);
    line-height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;
    /* margin-left: 1%; */
    /* margin-bottom: 2%;*/
    margin-top: 2%;
    align-items: center;
    /* text-transform: uppercase; */
    color: #009AE0;
    background: none !important;
    border: none !important;
    outline: none;
    cursor: pointer;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* identical to box height */


    color: #009AE0;

}

@media(max-width:700px) {
    .container {
        border-bottom: none;
        margin: 0px 16px;
    }

    .paraDesc {
        font-size: 14px;
    }

    .btnInAcc {
        font-size: 14px;
    }

    .paraComplete {
        font-size: 14px;
    }
}

/* 
.borderBottom {
    border-bottom: 1px solid #e2e2e2;
} */