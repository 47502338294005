.header {
    display: flex;
    align-items: center;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 1;
    background: #FFFFFF;
    padding: 16px;
    border-bottom: 1px solid #E5E5E5;
    font-weight: 450;
    font-size: 14px;
    line-height: 150%;
    /* identical to box height, or 21px */

    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
}

.backBtn {
    background: inherit;
    border: none;
    outline: none;
    padding: 0;
    display: flex;
}


.bodyContainer {
    display: flex;
    flex-direction: column;
    margin-top: 64px;
}