.container {
    /* display: flex; */
    width: 100%;
    /* margin: 0 16px 36px 16px; */
}

.tag {

    /* background: #FCF4DB;
    border: 1px solid #F2C94C; */
    box-sizing: border-box;
    border-radius: 4px;
    display: flex !important;
    font-weight: 450;
    font-size: 14px;
    line-height: 16px;
    /* identical to box height, or 114% */
    padding: 16px 12px;
    display: flex;
    align-items: flex-start;
    width: 100%;
    color: rgba(0, 0, 0, 0.87);
}

.marginZero {
    /* margin: 0 !important; */
}

.tag>.slick-dots {
    bottom: -4px !important;
}