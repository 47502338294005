@font-face {
  font-family: Circular;
  src: url(./CircularStd.ttf);
}

.couponsDiv {
  font-family: Circular;
  padding: 16px 24px;
  position: relative;
}

.CouponTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
  /* padding: 16px 24px; */
}
.Label {
  font-style: normal;
  /* font-weight: 600; */
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;

  color: rgba(0, 0, 0, 0.87);
}

.viewALLBtnMobile {
  font-family: Circular;
  font-style: normal;
  /* font-weight: 500; */
  font-size: 14px;
  line-height: 18px;
  background-color: transparent;
  border: none;
  outline: none;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: right;

  color: #009ae0;
}

.Scrollbutton {
  position: absolute;
  right: -30px;
  top: 70px;
  cursor: pointer;
  /* z-index: 1; */
}

.CouponWebBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.viewALLBtnDesktop {
  cursor: pointer;
  background: #ffffff;
  border: 1.2px solid #009ae0;
  box-sizing: border-box;
  border-radius: 4px;
  padding: 12px 70px;
  outline: none;

  font-style: normal;
  /* font-weight: 500; */
  font-size: 16px;
  line-height: 22px;
  /* identical to box height, or 137% */

  color: #009ae0;
}

.CouponBottom {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: auto;
  scroll-behavior: smooth;
  z-index: 1;
}

.CouponBottom::-webkit-scrollbar {
  display: none;
}

.coupon {
  padding: 16px;
  margin: 10px 16px 24px 0px;
  min-width: 167px;
  height: 71px;
  background: #ffffff;
  border: 1px solid #d9d9d9;
  box-sizing: border-box;
  box-shadow: 0px 1px 4px rgba(22, 29, 37, 0.05);
  border-radius: 8px;
  cursor: pointer;
  /* z-index: 2; */
}

.couponLabel {
  margin-bottom: 8px;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  color: #009ae0;
}

.couponName {
  height: 14px;
  font-style: normal;
  /* font-weight: normal; */
  font-size: 12px;
  line-height: 14px;

  color: rgba(0, 0, 0, 0.6);
}

/* COUPONS MODAL */

.CouponsModalTop {
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 0.5px solid #e7e7e7;
}
.CouponModalLabel {
  display: flex;
  flex-direction: row;
}
.CouponsModalTop > button {
  background-color: transparent;
  border: none;
  outline: none;
}

.CouponModalLabel > img {
  margin: 0 5px;
}

.CouponListItem {
  padding: 24px 24px 0 24px;
}

.couponList {
  max-height: 70vh;
  overflow-y: scroll;
}

.couponList::-webkit-scrollbar {
  width: 0;
}

.CouponModalRow1 {
  margin-top: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CouponModalRow2 {
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
  border-top: 1px dashed #e5e5e5;
  border-bottom: 1px dashed #e5e5e5;
  padding: 8px 0;
  align-items: center;
}

.CouponModalLabel {
  cursor: default;
  font-style: normal;
  /* font-weight: 600; */
  font-size: 14px;
  line-height: 19px;
  /* identical to box height */

  color: #282828;
}

.CouponModalName {
  cursor: default;
  font-style: normal;
  /* font-weight: normal; */
  /* font-weight: 500; */
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  color: #777777;
}

.CouponModalValid {
  cursor: default;
  font-style: normal;
  /* font-weight: 100; */
  font-size: 12px;
  line-height: 17px;
  /* identical to box height */

  text-align: right;

  color: #777777;
  display: flex;
  align-items: center;
}

.CouponModalCode {
  cursor: default;
  background-color: rgba(240, 169, 0, 0.05);
  border: 1px dashed #f5a623;
  box-sizing: border-box;
  border-radius: 2px;
  padding: 6px 11px;

  font-style: normal;
  /* font-weight: 700; */
  font-size: 12px;
  line-height: 14px;
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: 2px;

  color: rgba(0, 0, 0, 0.87);
}

.CouponModalLink {
  cursor: pointer;
  font-style: normal;
  /* font-weight: 700; */
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */

  display: flex;
  align-items: center;
  text-align: right;
  text-transform: capitalize;

  color: #009ae0;
}

.enterCouponWrapper {
  width: inherit;
  padding: 24px;
}

@media screen and (max-width: 700px) {
  .enterCouponWrapper {
    width: inherit;
    padding: 12px;
  }

}
.enterCoupon {
  padding: 12px 16px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

.enterCouponField {
  border: none;
  outline: none;

  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #282828;
}

.enterCouponButton {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: white;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;

  color: #009ae0;
}


.enterMainCouponWrapper {
  width: inherit;
  padding: 24px 0;
}


.enterMainCoupon {
  padding: 12px 16px;

  
  max-width: 490px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  border-radius: 4px;
}

.enterMainCouponField {
  border: none;
  outline: none;


  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #282828;
}

.enterMainCouponButton {
  cursor: pointer;
  border: none;
  outline: none;
  background-color: white;

  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: right;

  color: #009ae0;
}

@media screen and (max-width: 700px) {
  .enterMainCouponWrapper {
    width: inherit;
    padding: 16px 0px;
  }

  .enterMainCoupon {
    padding: 12px 12px;

  
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  
    border: 1px solid #e5e5e5;
    box-sizing: border-box;
    border-radius: 4px;
  }
  
  .enterMainCouponField {
    border: none;
    outline: none;
  
  
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    /* identical to box height */
  
    color: #282828;
  }
  
  .enterMainCouponButton {
    cursor: pointer;
    border: none;
    outline: none;
    background-color: white;
  
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    text-align: right;
  
    color: #009ae0;
  }

}


@media screen and (max-width: 700px) {
  .Scrollbutton {
    display: none;
  }

  .couponAppliedWrapper {
    position: fixed;
    z-index: 10;
    width: 100%;
  }

  .couponAppliedModal {
    background-color: #ffffff !important;

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

    text-align: center;

    background: #ffffff;
    box-shadow: 0px 1px 16px rgba(164, 164, 164, 0.6);
    border-radius: 6px;

    width: 90%;
    margin: 0 auto;

    padding: 36px 0;
  }

  .couponAppliedRow1 {
    margin-bottom: 20px;
  }

  .couponAppliedRow2 {
    margin-bottom: 36px;
  }

  .couponAppliedText1 {
    margin-bottom: 8px;

    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    text-align: center;

    color: rgba(0, 0, 0, 0.87);
  }

  .couponAppliedText2 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
    /* or 150% */

    text-align: center;

    color: rgba(0, 0, 0, 0.6);
  }

  .couponAppliedDone {
    cursor: pointer;

    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    /* identical to box height, or 137% */

    color: #009ae0;
  }

}

@media screen and (min-width: 700px) {
  .couponAppliedWrapper{
    display: none;
  }
}

