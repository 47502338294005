.ContentLockedModal {
    display: flex;
    flex-direction: column;
    margin: 28px 80px 58px 80px;
}
.ContentLockedModalHeadText {
    font-weight: 500;
font-size: 18px;
line-height: 27px;
margin-top: 16px;
display: flex;
justify-content: center;
align-items: center;
text-align: center;

color: rgba(0, 0, 0, 0.87);

}
.ContentLockedModalSubText {
    font-style: normal;
font-weight: 450;
font-size: 16px;
line-height: 22px;
margin-top: 16px;
justify-content: center;
display: flex;
align-items: center;
text-align: center;

color: rgba(0, 0, 0, 0.6);

}
.ContentLockedModalButton {
    background: #009AE0;
border-radius: 4px;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 20px;
margin-top: 24px;
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
color: #FFFFFF;
padding: 14px 0px;
outline: none;
border: none;
width: 100%;
}

.ContentLockedModalSecurePay {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 16px;
}

.ContentLockedModalSecurePay label {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 21px;
    /* identical to box height, or 150% */
    margin-left: 8px;
    display: flex;
    align-items: center;
    
    color: rgba(0, 0, 0, 0.6);
    
}

.ContentLockedModalHeading {
    font-style: normal;
font-weight: 450;
font-size: 16px;
line-height: 20px;
border-bottom:  0.5px solid #E7E7E7;
color: rgba(0, 0, 0, 0.87);
padding: 8px 24px 24px 24px;
mix-blend-mode: normal;
}

@media(max-width:700px){
    .ContentLockedModal {
        margin: 0px;
    }
    .ContentLockedModalHeadText {
       margin-left: 16px;
       margin-right: 16px;
    }
    .ContentLockedModalSubText {
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 100px;
    }
    .ContentLockedModalButton {
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 8px;
        width: 93%;
    }
    .ContentLockedModalSecurePay {
        margin-left: 16px;
        margin-right: 16px;
        margin-top: 24px;
    }
}

@media(max-width:375px){
    .ContentLockedModalButton {
        margin-left: 16px;
        margin-right: 16px;
        width: 92%;
    }
    .ContentLockedModalSubText {
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 75px;
    }
}

@media(max-width:325px){
    .ContentLockedModalButton {
        margin-left: 16px;
        margin-right: 16px;
        width: 91%;
    }
    .ContentLockedModalSubText {
        margin-left: 16px;
        margin-right: 16px;
        margin-bottom: 50px;
    }
}