.shareModal {
    background: #FFFFFF;
/* box-shadow: 0px 0px 8px rgba(151, 151, 151, 0.21708);
border-radius: 2px; */
}
.shareModalHeading {
    font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 24px;
margin-top: 16px ;
padding: 0px 16px 16px 16px;
border-bottom: 1px solid #E5E5E5;;
display: flex;
align-items: center;

color: rgba(0, 0, 0, 0.87);
}
.shareModalContainer {
    margin: 24px;
    display: flex;
    flex-direction: column;
}
.shareModalShareList{
    display: flex;
    justify-content: center;
}
.shareModalShareImg {
    padding: 8px 12px;
    cursor: pointer;
}
.shareModalCopyInput {
    margin-top: 23px;
    display: flex;
    justify-content: space-between;
    border: 1px solid #E5E5E5;
box-sizing: border-box;
border-radius: 4px;
padding: 13px 24px;
}
.shareModalCopyInputBox {
    width: 85%;
    border: none;
    font-style: normal;
font-weight: 450;
font-size: 14px;
line-height: 20px;
outline: none;
color: rgba(0, 0, 0, 0.6);
}
.shareModalCopyBtn {
    font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 20px;
outline: none;
border: none;
cursor: pointer;
display: flex;
align-items: center;
text-align: right;
background-color: white;
color: #009AE0;

}

.copySuccess {
    color: #009AE0;
    margin-top: 16px;
    text-align: center;
    -moz-animation: cssAnimation 0s ease-in 3s forwards;
    /* Firefox */
    -webkit-animation: cssAnimation 0s ease-in 3s forwards;
    /* Safari and Chrome */
    -o-animation: cssAnimation 0s ease-in 3s forwards;
    /* Opera */
    animation: cssAnimation 0s ease-in 3s forwards;
    -webkit-animation-fill-mode: forwards;
    animation-fill-mode: forwards;
}
@keyframes cssAnimation {
    to {
        width:0;
        height:0;
        overflow:hidden;
    }
}
@-webkit-keyframes cssAnimation {
    to {
        width:0;
        height:0;
        visibility:hidden;
    }
}

@media(max-width:700px){
    .shareModalHeading{
        font-size: 16px;
        margin-top: 12px ;
    }
    .shareModalContainer {
        margin: 24px 16px;
    }
    .shareModalCopyInput {
    padding: 13px 16px;
    }
}