.StudentViewed {
    margin: 24px 16px;
    display: none;
    flex-direction: column;
}

.StudentViewedHeading {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    opacity: 0.87;
}

.StudentViewedContentList {
    display: flex;
    flex-direction: column;
    margin: 0px 0px 24px 0px;
}

.StudentViewedContent {
    display: flex;
    /* align-items: center; */
    padding: 16px 0px;
    /* border-bottom: 1px solid #E5E5E5; */
}

.StudentViewedContentImg {
    /* width: 150px; */
    /* height: 100px; */
    position: relative;
    width: 110px;
    height: 80px;
    border: none;
    flex-shrink: 0;
}

.imageTag {
    padding: 0px 8px;
    position: absolute;
    top: 10px;
    left: 0px;
    background: #FF4058;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.16);
    border-radius: 0px 4px 4px 0px;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    color: #FFFFFF;
}

.StudentViewedContentText {
    display: flex;
    flex-direction: column;
    margin-left: 16px;
}

.StudentViewedContentRow1 {
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    opacity: 0.87;
    /* white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; */
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.StudentViewedContentRow2 {
    display: flex;
    margin-top: 8px;
}

.StudentViewedContentRupees {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;


    display: flex;
    align-items: center;

    color: rgba(0, 0, 0, 0.87);
}

.StudentViewedContentDiscount {
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    margin-left: 16px;

    display: flex;
    align-items: center;
    text-decoration-line: line-through;

    color: rgba(0, 0, 0, 0.45);
}

.StudentViewedContentOffer {
    font-style: normal;
    font-weight: 450;
    font-size: 12px;
    line-height: 15px;
    display: flex;
    align-items: center;
    margin-left: 16px;
    color: #FF5656;
    word-break: break-word;
}

.StudentViewedFooter {
    background: #FFFFFF;
    border: 1px solid #009AE0;
    box-sizing: border-box;
    border-radius: 4px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #009AE0;
    padding: 12px 0px;
}

@media(max-width:1000px) {
    .StudentViewed {
        display: flex;
        margin-bottom: 150px;
    }
}