.Container {
  position: fixed;
  top: 10vh;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 200;
  overflow-y: auto;
  height: 100%;
  overflow-x: hidden;
}

@media (max-height: 550px) {
  .Container {
    top: 55px;
    top: 0px;
  }
}

.Container::-webkit-scrollbar {
  width: 2px;
}

.Container::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 2px;
}

.Container::-webkit-scrollbar-track {
  background: #fff;
}

.Modal {
  position: absolute;
  /* position: relative; */
  z-index: 500;
  background-color: white;
  width: 70%;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 2px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  /* border-radius: 2px; */
  /* padding: 4px; */
  padding: 15px 2px;
  left: 15%;
  top: 5%;
  margin-bottom: 5%;
  box-sizing: border-box;
  /* transition: all 0.3s ease-in-out; */
}

.Modal .CloseBtn {
  cursor: pointer;
  width: 61px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 16px;
  right: 4px;
  background: white;
  border: 0;
  outline: none;
  font-size: 20px;
  border-radius: 10px;
}

.Content {
  min-height: 80px;

  font-size: 17px;
  font-weight: 500;
  /* font-family: 'Lato',
        sans-serif; */
}

.Container.Hidden {
  /* display: none; */
  transform: translateY(-100vh);
  opacity: 0;
  transition: all 0.3s ease-in-out;
  height: 0px;
}

.Container.Hidden .Modal {
  transform: translateY(-100vh);
  opacity: 0;
}

.Footer {
  width: 100%;
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Footer button {
  /* font-size: 1em;
  font-weight: 500; */
}

@media (min-width: 1000px) {
  .Modal {
    width: 500px;
    left: calc(50% - 250px);
    top: 10%;
    border: none;
  }

  .Container {
    top: 0;
  }
}

@media (max-width: 1000px) {
  .Modal .CloseBtn {
    background: #ffffff40;
    cursor: default;
    right: 0px;
  }

  .Modal {
    position: absolute;
    z-index: 500;
    background-color: white;
    width: 70%;
    border: none;
    border-radius: 2px;
    box-shadow: none;
    /* border-radius: 2px; */
    /* padding: 4px; */
    box-sizing: border-box;
    transition: all 0.3s ease-in-out;
    left: 0 !important;
    bottom: 0;
    margin: 0;
    top: auto;
    width: 100% !important;
    border-top-left-radius: 14px;
    border-top-right-radius: 14px;
    max-height: 92%;
    min-height: 0%;
    height: auto;
  }

  .Container {
    top: 0;
  }

  .Container.Hidden {
    /* display: none; */
    transform: translateY(100vh);
    opacity: 0;
    transition: all 0.3s ease-in-out;
    height: 0px;
  }

  .Container.Hidden .Modal {
    transform: translateY(100vh);
    opacity: 0;
  }

  .Footer {
    width: 100%;
    padding: 10px 0 10px 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Footer button {
    /* font-size: 1em;
      font-weight: 500; */
  }
}
