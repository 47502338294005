.CourseDuration {
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    border-top: 1px solid #E5E5E5;
    border-bottom: 1px solid #E5E5E5;
}

.CourseDurationHeading {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #000000;
    opacity: 0.87;
}

.CourseDurationImgContent {
    display: flex;
    margin-top: 16px;
}

.CourseDurationImg {
    width: 48px;
    height: 48px;
}

.CourseDurationContent {
    display: flex;
    flex-direction: column;
    margin-left: 12px;
}

.CourseDurationContentRow1 {
    font-style: normal;
    font-weight: 450;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.87);
    mix-blend-mode: normal;
}

.CourseDurationContentRow2 {
    font-style: normal;
    font-weight: 450;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.6);
    mix-blend-mode: normal;
}

@media(min-width:700px) {
    .CourseDuration {
        padding: 24px;
        margin: 24px 16px;
    }

    .CourseDurationHeading {
        font-size: 18px;
    }
}